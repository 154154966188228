import { createBrowserRouter } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { AuthRoute, ProtectedRoute } from './app/Layout/ProtectedRoute';
import { FallBack } from './app/pages/Common/FallBack';

const AppLayout = lazy(() => import('./app/Layout/AppLayout'));
const Dashboard = lazy(() => import('./app/pages/Dashboard'));
const Jobs = lazy(() => import('./app/pages/Jobs'));
const NotFound = lazy(() => import('./app/pages/Common/404NotFound'));
const Chatbot = lazy(() => import('./app/pages/Chatbot'));
const SignUp = lazy(() => import('./app/pages/Auth/SignUp'));
const Login = lazy(() => import('./app/pages/Auth/Login'));
const JobForm = lazy(() => import('./app/pages/Jobs/JobForm'));
const AIInterview = lazy(() => import('./app/pages/Interview/aiInterview'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<FallBack />}>
        <ProtectedRoute>
          <AppLayout />
        </ProtectedRoute>
      </Suspense>
    ),
    caseSensitive: true,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<FallBack />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: 'jobs',
        children: [
          {
            path: '',
            element: (
              <Suspense fallback={<FallBack />}>
                <Jobs />
              </Suspense>
            ),
          },
          {
            path: 'update/:id',
            element: (
              <Suspense fallback={<FallBack />}>
                <JobForm />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'chatbot',
        element: (
          <Suspense fallback={<FallBack />}>
            <Chatbot />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<FallBack />}>
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/signup',
    element: (
      <Suspense fallback={<FallBack />}>
        <AuthRoute>
          <SignUp />
        </AuthRoute>
      </Suspense>
    ),
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<FallBack />}>
        <AuthRoute>
          <Login />
        </AuthRoute>
      </Suspense>
    ),
  },
  {
    path: '/ai-interview',
    element: (
      <Suspense fallback={<FallBack />}>
        <AIInterview />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<FallBack />}>
        <NotFound />
      </Suspense>
    ),
  },
]);
