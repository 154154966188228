let setLoadingCallback = null;

export const setGlobalLoading = (loading) => {
  if (setLoadingCallback) {
    setLoadingCallback(loading);
  }
};

export const useGlobalLoader = (callback) => {
  setLoadingCallback = callback;
};
