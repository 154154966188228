import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
// import { Loader } from '../pages/Common/Loader';
import { axiosInstance } from '../Config/axiosInstance';
import { FallBack } from '../pages/Common/FallBack';

export const isAuthenticated = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const { data, error, status } = await axiosInstance.get(
        '/auth/verify_token/',
        {
          authRequired: true,
        }
      );
      if (status === 200) {
        localStorage.setItem('email', data.email);
        localStorage.setItem('id', data.id);
        localStorage.setItem('token', data.token);
        localStorage.setItem('full_name', data.full_name);
        localStorage.setItem('username', data.username);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('id');
        localStorage.removeItem('token');
        localStorage.removeItem('full_name');
        localStorage.removeItem('username');
        return false;
      }
    } catch (error) {
      localStorage.removeItem('email');
      localStorage.removeItem('id');
      localStorage.removeItem('token');
      localStorage.removeItem('full_name');
      localStorage.removeItem('username');
      return false;
    }
  }
  return !!token; // Return true if token exists, false otherwise
};

export const ProtectedRoute = ({ children }) => {
  // console.log('ProtectedRoute');
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await isAuthenticated();
      setAuth(authenticated);
    };
    checkAuth();
  }, []);

  if (auth === null) return <FallBack />; // Show loader while checking auth
  if (!auth) return <Navigate to='/login' />; // Redirect if not authenticated
  return children;
};

export const AuthRoute = ({ children }) => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await isAuthenticated();
      setAuth(authenticated);
    };
    checkAuth();
  }, []);

  if (auth === null) return <FallBack />; // Show loader while checking auth
  if (auth) return <Navigate to='/' />; // Redirect if already authenticated
  return children;
};
