export const Loader = () => {
  return (
    <div
      className='d-flex justify-content-center position-fixed'
      style={{
        height: '100vh',
        width: '100%',
        zIndex: 100000,
        backdropFilter: 'blur(2px)',
      }}
    >
      <div
        className='spinner-border m-auto blue-color'
        role='status'
        style={{
          width: '4rem',
          height: '4rem',
          borderWidth: '8px',
        }}
      >
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  );
};
