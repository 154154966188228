import axios from 'axios';
import { toast } from 'react-toastify';
import { setGlobalLoading } from './loaderState';

export const axiosInstance = axios.create({
  baseURL: 'https://ai-recruiter-api.alliancetek.net',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    setGlobalLoading(true);
    if (config.authRequired) {
      const token = localStorage.getItem('token');
      if (token) config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    setGlobalLoading(false);
    toast.error('Something went wrong');
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    setGlobalLoading(false);
    return response.data || response;
  },
  (error) => {
    setGlobalLoading(false);
    toast.error(error?.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);
